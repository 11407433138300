<template>
  <v-container>
    <v-row align-content="center" align="center" justify="center">
      <v-col cols="12" md="7">
        <v-img
            :src="require('../assets/logo.svg')"
            class="my-3"
            contain
            height="200"
        />
      </v-col>
      <v-col cols="12" md="2" class="text-center">
        <p class="mb-0 display-4">@</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-img :src="require('../assets/cnftcon_logo.png')" class="my-3" contain height="384" />
      </v-col>
    </v-row>
    <div class="text-center">
      <v-btn href="https://www.jpg.store/collection/cnftconlegacypass" color="secondary" x-large target="_blank">Buy a Legacy Pass</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "HeroBanner"
}
</script>

<style scoped>

</style>