import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#734e9d', // #E53935
                secondary: '#ED008c', // #FFCDD2
                accent: '#b71a8a', // #3F51B5
            }
        }
    }
});
