<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img :src="require('./assets/logo.svg')" alt="2500.club" title="2500.club" transition="scale-transition"
               contain height="36" />
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex flex-row align-content-center me-4" v-if="cardano.status === 'connected'">
        <v-img :src="cardano.ActiveWallet.icon" max-width="36" contain :alt="cardano.ActiveWallet.name" class="me-2" />
        <div class="text-capitalize">
          {{ cardano.ActiveWallet.name.replace(' Wallet', '') }} Connected<br />
          <v-progress-linear v-if="checkingBalance" color="secondary" indeterminate />
          <small v-if="!checkingBalance"> {{ formatAda(balance.lovelace) }} &#8371; <span
              v-if="balance.passes && balance.passes.length > 0">| {{ balance.passes.length }} Passes</span> </small>
        </div>
      </div>
      <v-btn v-if="cardano.status === 'connected'" class="me-2" @click="disconnect">
        Change Wallet
      </v-btn>
      <v-btn v-if="cardano.status === 'found'" color="secondary" class="me-2" @click="connecting = true">
        Connect Your Wallet
      </v-btn>
      <v-btn v-if="adaValue !== null" text>
        ADA-USD: ${{ adaValue.toLocaleString(undefined, lovelace_format) }}
        <v-icon v-if="adaValue != null && lastAdaValue != null && adaValue !== lastAdaValue"
                :color="adaValue > lastAdaValue ? 'light-green' : 'orange'">
          {{ adaValue > lastAdaValue ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-progress-linear indeterminate color="secondary" v-if="cardano.status === 'init'"
                         height="18"></v-progress-linear>
      <HeroBanner></HeroBanner>
      <v-container v-if="cardano.status === 'connected' && balance.passes && balance.passes.length > 0">
        <!--      <v-container>-->
        <h2>Yay, you've got a Legacy Pass. Congrats. Happy 4 You. Sorry for everything, goodbye!</h2>
        <v-btn color="primary" x-large class="my-4" @click="showDetails=!showDetails">Is This a Rug?</v-btn>
        <v-btn color="accent" x-large class="my-4 mx-2" @click="showNote=!showNote">On a Serious Note</v-btn>
        <div v-if="showDetails">
          <v-form ref="form" v-model="formValid" v-if="!orderDetails" :disabled="creatingOrder">
            <h1 class="my-4">cNFTcon 2023 Ticket Packages</h1>
            <h2>
              You can purchase one (1) package for each Legacy Pass you have, plus an extra one (1) package for a friend
              or family member for each Legacy Pass you have. </h2>
            <h4>
              This form allows you to purchase from the reserved quantity of Premium and Diamond Ticket Packages that
              are available for this year's event until February 1st, 2023. Regular, general admission tickets are
              available from the <a href="https://cnftcon.io/events/cnftcon2023" target="_blank">cNFTcon website</a> for
              $40 and include event access on Saturday, September 30th, 2023 and Sunday, October 1st, 2023. </h4>
            <v-row class="my-4">
              <v-col cols="12" md="6">
                <v-card color="teal">
                  <v-card-title class="justify-center">Premium Package</v-card-title>
                  <v-card-text>
                    <v-btn text block @click="tickets.premium.details=!tickets.premium.details">
                      {{ tickets.premium.details ? 'Hide Details' : 'Show Details' }}
                      <v-icon>{{ tickets.premium.details ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-card-text>
                  <v-card-text v-if="tickets.premium.details">
                    <ul>
                      <li>Preview Night Access (Friday, September 29th, 3-7pm)</li>
                      <li>Access to Friday Night Kickoff Party</li>
                      <li>Event Access Saturday, September 30th and Sunday, October 1st</li>
                      <li>Premium Package Tote Bag</li>
                      <li>Premium Package T-Shirt</li>
                      <li>Premium Package Lanyard + Badge</li>
                      <li>Premium Package Collectible NFT</li>
                      <li>Premium Package Collectible Enamel Pin</li>
                    </ul>
                  </v-card-text>
                  <v-card-title class="display-1 justify-center">
                    $120 <strike class="mx-2">$160</strike>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card color="accent">
                  <v-card-title class="justify-center">Diamond Package</v-card-title>
                  <v-card-text>
                    <v-btn text block @click="tickets.diamond.details=!tickets.diamond.details">
                      {{ tickets.diamond.details ? 'Hide Details' : 'Show Details' }}
                      <v-icon>{{ tickets.diamond.details ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-card-text>
                  <v-card-text v-if="tickets.diamond.details">
                    <ul>
                      <li>Preview Night Access (Friday, September 29th, 3-7pm)</li>
                      <li>Access to Friday Night Kickoff Party</li>
                      <li>Event Access Saturday, September 30th and Sunday, October 1st</li>
                      <li>VIP Lounge Access Saturday and Sunday</li>
                      <li>VIP Lounge Cash Bar</li>
                      <li>1 Catered Lunch Ticket for Saturday</li>
                      <li>1 Catered Lunch Ticket for Sunday</li>
                      <li>Diamond Package Hoodie</li>
                      <li>Diamond Package T-Shirt</li>
                      <li>Diamond Package Tote Bag</li>
                      <li>Diamond Package Lanyard + Badge</li>
                      <li>Diamond Package Collectible NFT</li>
                      <li>Diamond Package Collectible Enamel Pin</li>
                    </ul>
                  </v-card-text>
                  <v-card-title class="display-1 justify-center">
                    $600 <strike class="mx-2">$1,000</strike>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <label class="d-block mb-8">How many Premium Packages would you like to purchase?</label>
                <v-slider min="0" :max="6 - order.diamond" v-model="order.premium" thumb-label="always" class="mt-8"
                          color="teal"></v-slider>
              </v-col>
              <v-col cols="12" md="6">
                <label class="d-block mb-8">How many Diamond Packages would you like to purchase?</label>
                <v-slider min="0" :max="6 - order.premium" v-model="order.diamond" thumb-label="always" class="mt-8"
                          color="accent"></v-slider>
              </v-col>
            </v-row>
            <div>
              <label class="d-block mb-8">Total Tickets</label>
              <v-slider min="0" :max="6" :value="totalTickets()" readonly thumb-label="always" class="mt-8"></v-slider>
            </div>
            <div v-if="totalTickets() > 0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field readonly prefix="$" suffix="USD" label="Total Price (USD)"
                                :value="totalPriceUSD().toLocaleString(undefined, usd_format)"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field readonly prefix="₳" suffix="ADA" label="Estimated Price (ADA)"
                                :value="totalPriceADA().toLocaleString(undefined, lovelace_format)"></v-text-field>
                </v-col>
              </v-row>
              <v-alert color="primary" border="left" type="info">
                Your name, email, and address are required and will be used to create an account for you on cnftcon.io
                for the purpose of order fulfillment. We may contact you via email with important news or updates
                regarding cNFTcon 2023. After submitting your order, you will need to login with your supplied email at
                cnftcon.io in order to complete your payment (you can choose to pay with either USD or ADA).
              </v-alert>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field label="Your Name" v-model="order.name" required :rules="orderRules.name"></v-text-field>
                  <v-text-field label="Your Email" v-model="order.email" required
                                :rules="orderRules.email"></v-text-field>
                  <v-text-field label="Your Phone Number" v-model="order.phone"></v-text-field>
                  <v-dialog ref="dialog" v-model="dp" :return-value.sync="order.dob" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="order.dob" label="Your Date of Birth" prepend-icon="mdi-calendar" readonly
                                    v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="order.dob" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dp = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(order.dob)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="Street Address" required :rules="orderRules.street1"
                                v-model="order.street1"></v-text-field>
                  <v-text-field label="Street Address 2" v-model="order.street2"></v-text-field>
                  <v-text-field label="City" required v-model="order.city" :rules="orderRules.city"></v-text-field>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field label="State/Province" v-model="order.state"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="Country" required v-model="order.country"
                                    :rules="orderRules.country"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-text-field label="Postal Code" required :rules="orderRules.postal"
                                v-model="order.postal"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <p>
                    This is why people with NFTs hate on people like me when I paperhand but they don't know my story or
                    financial status. if I can get my bread at 100 ADA and the top is 1k ADA just let me live my life
                    and no need to be judgmental gais. <strong>I understand that all cNFTcon 2023 ticket sales are final
                                                               and non-refundable.</strong> I have read and agree to be
                    bound by the cNFTcon <a href="https://cnftcon.io/terms-and-conditions" target="_blank">Terms and
                                                                                                           Conditions of
                                                                                                           Service</a>,
                    <a href="https://cnftcon.io/privacy-policy" target="_blank">Privacy Policy</a>, and <a
                      href="https://cnftcon.io/event-policies/" target="_blank">Event Policy</a>. </p>
                  <v-switch v-model="order.terms" :label="`I agree to all of the above`" :rules="orderRules.terms"
                            required></v-switch>
                </v-col>
              </v-row>

              <v-btn :disabled="!formValid" :loading="creatingOrder" x-large color="primary" @click="createOrder">
                Create Order
              </v-btn>

            </div>

          </v-form>
          <div v-if="orderDetails">
            <v-alert color="teal" type="success" border="left">
              YOUR ORDER HAS BEEN RECORDED!
            </v-alert>
            <p class="body-1">
              Your order has been recorded at cNFTcon.io and is ready for payment. Please login to <a
                href="https://cnftcon.io/my-account/orders/" target="_blank">Your Account at cNFTcon.io</a> and visit
              the "Orders" tab to complete payment for your order. </p>
            <p class="body-1">
              Your Order Number is: {{ orderDetails.order_id }} </p>
            <v-btn x-large color="secondary" :href="orderDetails.payment_url" target="_blank">Click Here to Pay Now
            </v-btn>
          </div>
        </div>

        <!--        <h2>Yay, you've got a Legacy Pass. Congrats. Happy 4 You.</h2>-->

      </v-container>
    </v-main>
    <v-dialog v-model="connecting" max-width="512">
      <v-card>
        <v-card-title>Connect Your Wallet</v-card-title>
        <v-card-text>
          <v-btn v-for="wallet in cardano.Wallets" :key="wallet.name" block class="mb-2 text-start" x-large
                 @click="connectTo(wallet)" :loading="wallet.loading">
            <v-img :src="wallet.icon" max-width="24" height="24" class="me-2" contain :alt="wallet.name"></v-img>
            Connect {{ wallet.name.replace(' Wallet', '') }}
          </v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNote" max-width="512">
      <v-card>
        <v-card-title>On a Serious Note...</v-card-title>
        <v-card-text>
          cNFTcon would not exist without the support of you, our Legacy Pass holders. Fondly referred to as our
          #2500Club! It's impossible for me to properly put into words the gratitude I have for the entire community.
        </v-card-text>
        <v-card-text>
          We are striving and dedicated to providing a safe, welcoming, fun and community-focused event in 2023 and for
          many years to come. cNFTcon is our chance to gather, in person, and further galvanize and strengthen the bonds
          of community that bind us together.
        </v-card-text>
        <v-card-text>
          I sincerely and honestly hope that you will be able to join us in Las Vegas for cNFTcon 2023 or at one of our
          #RoadtocNFTcon events throughout the year.
        </v-card-text>
        <v-card-text>
          See you in Vegas!
        </v-card-text>
        <v-card-text class="text-end">
          <strong>Adam Dean</strong><br /> <em>A Prominent Cardano Dev</em>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

import axios from "axios";
import HeroBanner from "@/components/HeroBanner";

export default {
  name: 'App',
  components: {HeroBanner},
  data: () => ({
    tickets: {
      premium: {
        details: false,
        price: 120
      },
      diamond: {
        details: false,
        price: 600
      }
    },
    dp: false,
    showDetails: false,
    showNote: false,
    connecting: false,
    checkingBalance: false,
    creatingOrder: false,
    lastAdaValue: null,
    adaValue: null,
    balance: {},
    policy_id: '5fa72fbeecbe80a3e15de1cacab54ba5e310e2c36ae85351132ed4ad',
    lovelace_format: {
      maximumFractionDigits: 6,
      minimumFractionDigits: 6,
    },
    usd_format: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    },
    order: {
      terms: false,
      premium: 0,
      diamond: 0
    },
    formValid: true,
    orderDetails: null,
    orderRules: {
      name: [
        v => !!v || 'Your name is required!'
      ],
      email: [
        v => !!v || 'Your email is required!'
      ],
      street1: [
        v => !!v || 'You must provide a street address'
      ],
      city: [
        v => !!v || 'You must provide your city'
      ],
      country: [
        v => !!v || 'You must provide your country'
      ],
      postal: [
        v => !!v || 'You must provide your postal code'
      ],
      terms: [
        v => !!v || 'You must agree to the above listed terms of service, privacy, and behavior policy to place your order!'
      ]
    }
  }),
  methods: {
    async createOrder() {
      this.creatingOrder = true;
      this.order.stake_key = this.cardano.stake_key;
      this.order.passes = this.balance.passes;
      let response;
      try {
        response = await axios.post('https://2500.club/api/v1/presale/submit/', this.order);
      } catch (e) {
        console.error("Order Creation Error:", e);
        this.creatingOrder = false;
      }

      this.orderDetails = response.data;
      this.creatingOrder = false;
    },
    totalPriceUSD() {
      const premium_quantity = parseInt(this.order.premium);
      const diamond_quantity = parseInt(this.order.diamond);
      return (premium_quantity * this.tickets.premium.price) + (diamond_quantity * this.tickets.diamond.price);
    },
    totalPriceADA() {
      return this.totalPriceUSD() / this.adaValue;
    },
    totalTickets() {
      return parseInt(this.order.premium) + parseInt(this.order.diamond);
    },
    formatAda(lovelace) {
      const ada_value = this.toAda(lovelace);
      return ada_value.toLocaleString(undefined, this.lovelace_format);
    },
    disconnect() {
      this.order = {
        terms: false,
        premium: 0,
        diamond: 0
      };
      this.orderDetails = null;
      this.changeWallet();
      this.balance = {};
    },
    async connectTo(wallet) {
      wallet.loading = true;
      this.checkingBalance = true;
      await this.connect(wallet);
      await this.checkForBalance();
      this.checkingBalance = false;
      wallet.loading = false;
      this.connecting = false;
    },
    async checkForBalance() {
      this.balance.lovelace = await this.checkBalance();
      this.balance.passes = await this.getTokens(this.policy_id);
      console.log(this.balance.passes);
    },
    async getAdaPrice() {
      const adausd = await axios.get('https://adosia.buffybot.io/api/v1/price/adausd/');
      if (adausd.data.price) {
        if (this.adaValue !== null) {
          this.lastAdaValue = this.adaValue;
        }
        this.adaValue = adausd.data.price;
      }
    },
  },
  async mounted() {
    this.checkForCardano();


    await this.getAdaPrice();

    setInterval(() => {
      this.getAdaPrice();
    }, 60000);
  }
};
</script>
