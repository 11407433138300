<template>
  <v-container>

  </v-container>
</template>

<script>

  export default {
    name: 'HomeSlice',

    components: {
    },
    data: () => ({
      // cardano: this.$parent.cardano;
    })
  }
</script>
